import React from 'react';
import {Nav} from "../../components";
import Footer from "../../components/footer";
import '../../style/about.scss';

const About = ({location}) => {
    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'About Swaptecs'}
            darkNav={true}
        />

        <div className="pageContainer about">
            <section className={'intro'}>
                <h1>Selling a phone is overcomplicated. We help you solve it.</h1>

                <p>
                    Since 2020, Swaptecs has been connecting companies to their customers on billions of devices around the
                    world. Our versatile and reliable communication APIs have been built in-house by some of the top
                    telephony engineers in the business. To power these operations, we have offices on 4 continents and a
                    world-class team of over 20 different nationalities available around the clock to support you.
                </p>

                <p>
                    Today, over 15,000 global customers rely on Swaptecs to solve their communication challenges by routing
                    important messages and calls on their behalf. Our SMS, Chat, and Voice APIs continue to revolutionize
                    company-customer interactions, making contact easier, more efficient, and more accessible than ever
                    before.
                </p>
            </section>

            <section>
                <div className="cta row left">
                    <h3>
                        <span className='quote'>“</span>
                        <span>We want to be the platform that all people use to sell their used devices.</span>
                    </h3>
                </div>
            </section>
        </div>

        <Footer />
    </div>
}

export default About;